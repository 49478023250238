<template>
  <div>
    <div>
      <Loader v-if="isPageLoading" />
      <router-view v-else :key="$route.fullPath" />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Elements/Loader.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    Loader,
  },
  mounted() {},
  computed: {
    ...mapGetters(["isPageLoading"]),
  },
};
</script>
<style>
body,
html {
  background-color: #f9f6ee;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
