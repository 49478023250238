import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import BaseRoutes from "./BaseRoutes";
import store from "@/store";
import TokenService from "@/common/token.service";

const routes = [
  ...BaseRoutes,
  {
    path: "/maintenance",
    name: "Maintenance",

    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  var pricingPlan = TokenService.getToken("pricing_plan");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (!store.getters.isAuthenticated) {
      if (pricingPlan) {
        next({
          name: "Payment",
        });
      } else {
        next({
          name: "Register",
          query: { redirect: to.fullPath },
        });
      }
    } else {
      next();
    }
  } else {
    if (store.getters.isAuthenticated && (to.name == "Login" || to.name == "Register")) {
      if (pricingPlan) {
        next({
          name: "Payment",
        });
      } else {
        next({ name: "Dashboard" });
      }
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
