export const config = {
  url: process.env.VUE_APP_API_URL,
  businessUrl: process.env.VUE_APP_BUSINESS_URL,
  customerUrl: process.env.VUE_APP_CUSTOMER_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  "validateCustomer": "/7BUXsewaL2PSnh2jeWQ4CgccY0_F-_AbvafW3TLcf_Q=",
  "validateOTP": "/6DQ2cycjBTd2T6LiCOI4DiTiq7v6XBi5peGW81XG998=",
  "resendOTP": "/02QipOr9fLXgjyjd7xqL54gtKGEERggJtZf-d-I4Wf4=",
  "loginValidateEmail": "xfky9cjKd43BaDjJkrNu-xWpwcouYc_FPzW2NKe5OFw=",
  "loginValidateOTP": "Fdhyf97OR6gd_r5mOmVYEy8CSNmpSFpGW9GXYLvB9eY=",
  "loginResendOTP": "0aN2p5ZbkWpLiNC88POwMrsBpgHk6PF3Ip7McwO8qKs=",
  "profile": "l9uQCVnZVEF4iWWM73ZoGaMomq6E6rWQN4GOt4FNzs4=",
  "getPricingDetails": "/7-MYDe93rwAcu4rL2Sr_8xEdS6KXu7ipRqFKuQ-OSbE=",
  "getPricingDetailsFromSlug":"/317VkiHxjBwaUbOASbdbS8bONutt6rsrCkGrKwKQ1xY=",
  "continueWithGoogle": "/fNRpjD5ez2pZ1UengQSRq7SRN75UoEsG187YAeRj5Zk=",
  gameJams: {
    getDetails: "/x3GDwA1eDSon273QNJxsfWOv08Rf50U27nR935dvJIc=",
    createRazorpayOrder: "/9NbxERTSNgMed1kxqPJpSEyXVb2qtZ26Rv3Szj0b7ME=",
    confirmPayment: "/N8pAcwbfsZgFsFCCmilUuWGSfmhHU-cNvEjaypu9O5A=",
    saveProgress: "/OAYn5IHOHouA2A9CG-ZxmqGhgJDZ8NFcNectFtG36_c=",
    uploadLogo: "/FL1ExxLWwEkOVYeF1TYcveoLEA5groQ71zktXedXwoU=",
    removeLogo: "/ObjW1nunH4mrCx9zyDNhEk6YsfeuQtBW_g_ne8oD6fI=",
    skipStep: "/fJ44XbZw7mNvwX7BQSY3kR0jV_FWoge2qBE0GQFzJ3Q=",
    updateTheme: "/Hg-aBPypnFrtKXj3_10nEoR8XhEAe0JIFc3usIwrfJA="
  }
};