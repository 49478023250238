export default [
  {
    path: "/",
    name: "Pricing",

    component: () =>
      import(/* webpackChunkName: "pricing" */ "@/views/Pricing.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "Register",

    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/payment",
    name: "Payment",

    component: () =>
      import(/* webpackChunkName: "payment" */ "@/views/Payment.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",

    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/create-game-jam/:game_jam_id?/:step",
    name: "CreateGameJam",

    component: () =>
      import(/* webpackChunkName: "creategamejam" */ "@/views/CreateGameJam.vue"),
    meta: { requiresAuth: true },
  }
];
